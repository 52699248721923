import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setName, setDateOfBirth, setLocation } from "../../store/userSlice";
import Title from "components/Title/Title";
import InputName from "components/Input/InputName/InputName";
import InputDate from "components/Input/InputDate/InputDate";
import InputLocation from "components/Input/InputLocation/InputLocation";
import Button from "components/Button/Button";
import styles from "./UserRegistration.module.scss";
import Stepper from "components/Stepper/Stepper";

const UserRegistration: React.FC = () => {
  const [name, setNameLocal] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [location, setLocationLocal] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsButtonDisabled(!(name && date && location));
  }, [name, date, location]);

  const handleNextClick = () => {
    navigate("/register/about");
  };

  const handleDateChange = (formattedDate: string) => {
    setDate(formattedDate);
    dispatch(setDateOfBirth(formattedDate));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNameLocal(value);
    dispatch(setName(value));
  };

  const handleLocationChange = (value: string) => {
    setLocationLocal(value);
    dispatch(setLocation(value));
  };

  return (
    <div className={styles.userRegistration}>
      <Stepper currentStep={1} />
      <div className={styles.title}>
        <Title firstTitle="Начнем!" lastTitle="Давайте познакомимся!" />
      </div>
      <div className={styles.fieldsContainer}>
        <InputName placeholder="Ваше Имя" onChange={handleNameChange} />
        <InputDate onDateChange={handleDateChange} />
        <InputLocation onLocationChange={handleLocationChange} />
      </div>

      <div className={styles.button}>
        <Button
          title="Продолжить"
          onClick={handleNextClick}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default UserRegistration;
