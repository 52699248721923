import { useEffect, useState } from "react";
import { fetchNextForm } from "../services/formService";

interface UserProfile {
  id: string;
  name: string;
  photo_links: string[];
  description: string;
  age: number;
}

const useFetchProfiles = () => {
  const [profiles, setProfiles] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchNext = async () => {
    try {
      setLoading(true);
      const profileData = await fetchNextForm();
      setProfiles((prevProfiles) => [...prevProfiles, profileData]);
      setLoading(false);
    } catch (err) {
      setError("Не удалось загрузить профили");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNext();
  }, []);

  return { profiles, loading, error, fetchNext };
};

export default useFetchProfiles;
