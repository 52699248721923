import axios from "axios";

const baseUrl = "https://swiperapp.ru:3000";

export const likeForm = async (formId) => {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) {
    throw new Error("Access token отсутствует");
  }

  try {
    const response = await axios.post(
      `${baseUrl}/reaction/like/form/${formId}`,
      null,
      {
        headers: {
          "X-Auth-Token": accessToken,
          "X-Community-Id": "0e46a5d2-91d5-4669-988c-d7148c350c07",
        },
      }
    );

    if (response.status === 200) {
      console.log("Лайк установлен:", response.data);
      return response.data;
    } else {
      throw new Error(`Unexpected response code: ${response.status}`);
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      throw new Error(JSON.stringify(error.response.data.detail));
    } else {
      console.error("Ошибка при установке лайка:", error);
      throw error;
    }
  }
};

export const dislikeForm = async (formId) => {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) {
    throw new Error("Access token отсутствует");
  }

  try {
    const response = await axios.post(
      `${baseUrl}/reaction/dislike/form/${formId}`,
      null,
      {
        headers: {
          "X-Auth-Token": accessToken,
          "X-Community-Id": "0e46a5d2-91d5-4669-988c-d7148c350c07",
        },
      }
    );

    if (response.status === 200) {
      console.log("Дизлайк установлен:", response.data);
      return response.data;
    } else {
      throw new Error(`Unexpected response code: ${response.status}`);
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      throw new Error(JSON.stringify(error.response.data.detail));
    } else {
      console.error("Ошибка при установке дизлайка:", error);
      throw error;
    }
  }
};
