import axios from "axios";

export const fetchNextForm = async () => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const XCommunityId = "0e46a5d2-91d5-4669-988c-d7148c350c07";

    if (!accessToken) {
      throw new Error("Access token missed");
    }

    console.log("Access token:", accessToken);
    console.log("X-Community-Id:", XCommunityId);

    console.log("Requesting the next user's data");

    const response = await axios.post(
      "https://swiperapp.ru:3000/form/next",
      null,
      {
        headers: {
          "X-Auth-Token": accessToken,
          "X-Community-Id": XCommunityId,
        },
      }
    );

    console.log("Next user data successfully received: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to request next user's data:", error);
    throw error;
  }
};
