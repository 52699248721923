import axios from "axios";

export const loginUser = async (InitData) => {
  try {
    const response = await axios.post(
      "https://swiperapp.ru:3000/auth/login",
      null,
      {
        params: {
          InitData: InitData,
        },
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response?.data) {
      const { user, access_token } = response.data;

      if ((user, access_token)) {
        localStorage.setItem("access_token", access_token);
      }

      return response.data;
    }

    return null;
  } catch (error) {
    throw error;
  }
};
