import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAbout } from "../../store/userSlice";
import { RootState } from "../../store";
import Title from "components/Title/Title";
import InputAbout from "components/Input/InputAbout/InputAbout";
import Button from "components/Button/Button";
import styles from "./UserAbout.module.scss";
import Stepper from "components/Stepper/Stepper";
import Text from "components/Text/Text";

const UserAbout: React.FC = () => {
  const aboutTextLocal = useState<string>("");
  const [aboutText, setAboutTextLocal] = aboutTextLocal;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    setIsButtonDisabled(!aboutText.trim());
  }, [aboutText]);

  const handleNextClick = () => {
    navigate("/register/photo-agreement");
  };

  const handleAboutChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setAboutTextLocal(value);
    dispatch(setAbout(value));
  };

  return (
    <div className={styles.registerStepTwo}>
      <Stepper currentStep={2} />
      <div className={styles.title}>
        <Title firstTitle="Расскажите о себе" />
      </div>
      <Text />
      <div className={styles.inputAbout}>
        <InputAbout text={aboutText} onChange={handleAboutChange} />
      </div>
      <div className={styles.button}>
        <Button
          title="Продолжить"
          onClick={handleNextClick}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default UserAbout;
