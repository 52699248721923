import React from "react";
import classNames from "classnames";
import { MainPageIcon } from "../../icons/MainPageIcon";
import { ChatsPageIcon } from "../../icons/ChatsPageIcon";
import { ProfilePageIcon } from "../../icons/ProfilePageIcon";

import styles from "./MainMenu.module.scss";

const enum TabsList {
  Main = "Главная",
  Matches = "Совпадения",
  Profile = "Профиль",
}

interface MainMenuProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const MainMenu: React.FC<MainMenuProps> = ({ activeTab, setActiveTab }) => {
  const tabs = [
    {
      name: TabsList.Main,
      icon: <MainPageIcon />,
      active_icon: <MainPageIcon fill="#32ADE6" />,
    },
    {
      name: TabsList.Matches,
      icon: <ChatsPageIcon />,
      active_icon: <ChatsPageIcon fill="#32ADE6" />,
    },
    {
      name: TabsList.Profile,
      icon: <ProfilePageIcon />,
      active_icon: <ProfilePageIcon fill="#32ADE6" />,
    },
  ];

  const onTabClick = (tab: TabsList) => () => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.container}>
      {tabs.map(({ name, icon, active_icon }, index) => {
        return (
          <div
            className={classNames(styles.tab, {
              [styles.tab_active]: name === activeTab,
            })}
            key={`${name}_${index}`}
            onClick={onTabClick(name)}
          >
            {activeTab === name ? active_icon : icon}
            <p>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MainMenu;
