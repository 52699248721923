import React, { useState } from "react";
import UserProfiles from "components/UserProfiles/UserProfiles";
import MainMenu from "components/MainMenu/MainMenu";
import HintOnCommonFunctions from "../../components/HintOnCommonFunctions/HintOnCommonFunctions";
import Profile from "../../components/Profile/Profile";

import styles from "./MainSession.module.scss";
import CoincidencesMenu from "../../components/CoincidencesMenu/CoincidencesMenu";

const MainSession: React.FC = () => {
  const [isHintsClosed, setIsHintsClosed] = useState(false);
  const [activeTab, setActiveTab] = useState("Главная");

  const onHintsClick = () => {
    setIsHintsClosed(true);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Главная":
        return <UserProfiles />;
      case "Совпадения":
        return <CoincidencesMenu />;
      case "Профиль":
        return <Profile />;
      default:
        return <UserProfiles />;
    }
  };

  return (
    <div className={styles.container}>
      <HintOnCommonFunctions isClosed={isHintsClosed} onClick={onHintsClick} />
      <div className={styles.content}>
      {renderContent()}
      </div>
      <MainMenu activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
};

export default MainSession;
