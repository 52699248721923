import axios from "axios";

export const registerUser = async (InitData) => {
  try {
    const response = await axios.post(
      "https://swiperapp.ru:3000/auth/register",
      null,
      {
        params: {
          InitData: InitData,
        },
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response.data?.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
    }

    return response.data;
  } catch (error) {
    console.error("Registrations failed: ");
    console.log(error.toJSON());
    throw error;
  }
};
