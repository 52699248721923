import React, { useState, useEffect } from "react";
import styles from "./AddPhoto.module.scss";
import { PhotoIcon } from "icons/PhotoIcon";
import { CancelIcon } from "icons/CancelIcon";
import classNames from "classnames";

interface AddPhotoProps {
  onPhotoChange: (files: (File | null)[]) => void; // Передаем функцию для передачи файлов в родитель
  length?: number;
  className?: string;
}

const AddPhoto: React.FC<AddPhotoProps> = ({
  onPhotoChange,
  length = 4,
  className,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>(
    Array.from({ length }, () => null),
  );
  const [previews, setPreviews] = useState<(string | ArrayBuffer | null)[]>(
    Array.from({ length }, () => null),
  );

  useEffect(() => {
    onPhotoChange(selectedFiles); // Отправляем файлы в родительский компонент при изменении
  }, [selectedFiles, onPhotoChange]);

  const handleFileChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = file;
      setSelectedFiles(newSelectedFiles);

      const reader = new FileReader();
      reader.onloadend = () => {
        const newPreviews = [...previews];
        newPreviews[index] = reader.result;
        setPreviews(newPreviews);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.addPhotoContainer}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className={classNames(styles.addPhotoItem, className)}>
          <label htmlFor={`fileInput${index}`} className={styles.iconLabel}>
            {previews[index] ? (
              <img
                src={previews[index] as string}
                alt={`Preview ${index + 1}`}
                className={styles.previewImage}
              />
            ) : (
              <PhotoIcon />
            )}
          </label>
          <input
            id={`fileInput${index}`}
            type="file"
            accept="image/*"
            onChange={(event) => handleFileChange(index, event)}
            className={styles.hiddenInputBtn}
          />
          <div className={styles.crossIcon}>
            <CancelIcon />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddPhoto;
