import React from "react";
import buttonStyles from "components/Button/Button.module.scss";

const TelegramBotLink: React.FC = () => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <h2 style={{ marginBottom: "10px" }}>Добро пожаловать!</h2>
    <p style={{ marginBottom: "20px" }}>
      Пожалуйста, запустите приложение через нашего бота в Telegram.
    </p>
    <a
      href="https://t.me/SwiperAppBot"
      target="_blank"
      rel="noopener noreferrer"
      className={buttonStyles.button}
      style={{ textDecoration: "none" }}
    >
      Перейти в Telegram
    </a>
  </div>
);

export default TelegramBotLink;
