import React from "react";
import MainMenu from "components/MainMenu/MainMenu";
import Profile from "components/Profile/Profile";
import styles from "./EditProfile.module.scss"

const EditProfile: React.FC = () => {
  return (
    <div className={styles.EditProfileContainer}>
      <Profile />
    </div>
  );
};

export default EditProfile;
