import React, { useState } from "react";
import Stepper from "components/Stepper/Stepper";
import classNames from "classnames";
import styles from "./CoincidencesMenu.module.scss";

const CoincidencesMenu: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className={styles.coincidencesMenu}>
      <div className={styles.menuTabs}>
        <div
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 1,
          })}
          onClick={() => handleTabClick(1)}
        >
          Совпадения
        </div>
        <div
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 2,
          })}
          onClick={() => handleTabClick(2)}
        >
          Мои лайки
        </div>
        <div
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 3,
          })}
          onClick={() => handleTabClick(3)}
        >
          Меня лайкнули
        </div>
      </div>

      <Stepper currentStep={activeTab} />

      <div className={styles.content}>
        {activeTab === 1 && <div></div>}
        {activeTab === 2 && <div></div>}
        {activeTab === 3 && <div></div>}
      </div>
    </div>
  );
};

export default CoincidencesMenu;
