import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import UserRegistration from "pages/UserRegistration/UserRegistration";
import UserAbout from "pages/UserAbout/UserAbout";
import UserPhotoAgreement from "pages/UserPhotoAgreement/UserPhotoAgreement";
import Container from "components/Container/Container";
import "react-toastify/dist/ReactToastify.css";
import "./scss/App.scss";
import Logo from "components/Logo/Logo";
import MainSession from "pages/MainSession/MainSession";
import EditProfile from "pages/EditProfile/EditProfile";
import AuthWrapper from "components/AuthWrapper/AuthWrapper";

function App() {
  return (
    <div className="App">
      <ToastContainer position="bottom-right" />
      <Router>
        <Container>
          <Logo />
          <Routes>
            <Route path="/" element={<AuthWrapper />} />
            <Route path="/register/user" element={<UserRegistration />} />
            <Route path="/register/about" element={<UserAbout />} />
            <Route
              path="/register/photo-agreement"
              element={<UserPhotoAgreement />}
            />
            <Route path="/main-session" element={<MainSession />} />
            <Route path="/profile" element={<EditProfile />} />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;
