import React, { useState } from "react";
import AddPhoto from "components/AddPhoto/AddPhoto";
import styles from "./Profile.module.scss";
import { VerifiedIcon } from "icons/VerifiedIcon";
import Title from "components/Title/Title";
import AddPhotoInCircle from "components/AddPhotoInCircle.tsx/AddPhotoInCircle";
import { InputName } from "components/Input/InputName/InputName";
import { InputDate } from "components/Input/InputDate/InputDate";
import { InputLocation } from "components/Input/InputLocation/InputLocation";
import InputAbout from "components/Input/InputAbout/InputAbout";
import MainMenu from "components/MainMenu/MainMenu";

const Profile: React.FC = () => {
  const [firstPhoto, setFirstPhoto] = useState<string | null>(null);

  const handlePhotoChange = (files: (File | null)[]) => {
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFirstPhoto(reader.result as string);
      };
      reader.readAsDataURL(files[0] as File);
    }
  };

  return (
    <div className={styles.container}>
      <AddPhotoInCircle photo={firstPhoto} />
      <div className={styles.nameAndAge}>
        <Title firstTitle={"Владлена, 24"} className={styles.name} />
        <VerifiedIcon />
      </div>
      <Title firstTitle={"Общая анкета"} className={styles.title} />
      <AddPhoto
        onPhotoChange={handlePhotoChange}
        length={2}
        className={styles.addPhoto}
      />
      <Title firstTitle={"Информация о вас"} className={styles.userInfo} />
      <InputName
        onChange={() => {}}
        text={"Владлена"}
        className={styles.userName}
      />
      <InputDate onDateChange={() => {}} />
      <InputLocation onLocationChange={() => {}} />
      <Title firstTitle={"Ваше описание"} className={styles.userDescription} />
      <InputAbout
        text={
          "Я хочу найти людей, с которыми смогу работать над проектом. Нужны дизайнеры, разработчики, аналитики, пишите все, кто готов участвовать в проекте. Задачи найдем для всех! Нужны дизайнеры, разработчики, аналитики, пишите все, кто готов участвовать в проекте. Задачи найдем для всех! Нужны дизайнеры, разработчики, аналитики."
        }
        onChange={() => {}}
        showInputWithIcon={false}
      />
      <Title
        firstTitle={"Ваши сообщества"}
        className={styles.userDescription}
      />
    </div>
  );
};

export default Profile;
