import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "services/authService";
import TelegramBotLink from "components/TelegramBotLink/TelegramBotLink";

interface InitDataUnsafe {
  auth_date?: number;
  user?: object;
  hash?: string;
  query_id?: string;
}

const AuthWrapper: React.FC = () => {
  const navigate = useNavigate();
  const [shouldRenderTelegramBotLink, setShouldRenderTelegramBotLink] =
    useState<boolean>(false);

  useEffect(() => {
    const performLogin = async () => {
      const initDataUnsafe: InitDataUnsafe | undefined =
        window.Telegram?.WebApp?.initDataUnsafe;

      const isValidInitData =
        initDataUnsafe &&
        typeof initDataUnsafe.auth_date !== "undefined" &&
        typeof initDataUnsafe.user !== "undefined" &&
        typeof initDataUnsafe.hash !== "undefined" &&
        typeof initDataUnsafe.query_id !== "undefined";

      if (!isValidInitData) {
        setShouldRenderTelegramBotLink(true);
        return;
      }

      const userString = encodeURIComponent(
        JSON.stringify(initDataUnsafe.user)
      );
      const initData = `auth_date=${initDataUnsafe.auth_date}&user=${userString}&hash=${initDataUnsafe.hash}&query_id=${initDataUnsafe.query_id}`;

      try {
        const userData = await loginUser(initData);
        if (userData) {
          navigate("/main-session");
        } else {
          navigate("/register/user");
        }
      } catch {
        navigate("/register/user");
      }
    };

    performLogin();
  }, [navigate]);

  if (shouldRenderTelegramBotLink) {
    return <TelegramBotLink />;
  }

  return null;
};

export default AuthWrapper;
