import React, { FC, useState } from "react";
import styles from "./UserProfiles.module.scss";
import { VerifiedIcon } from "icons/VerifiedIcon";
import Status from "components/Status/Status";
import Button from "components/Button/Button";
import { PreviousIcon } from "icons/PreviousIcon";
import { LikeIcon } from "icons/LikeIcon";
import { CancelIcon } from "icons/CancelIcon";
import useFetchProfiles from "hooks/useFetchProfiles";
import { likeForm, dislikeForm } from "services/reactionService";
import { GroupButtonLocal } from "../GroupButtonLocal/GroupButtonLocal";

interface UserProfile {
  id: string;
  name: string;
  photo_links: string[];
  description: string;
  age: number;
}

const UserProfiles: FC = () => {
  const { profiles, loading, error, fetchNext } = useFetchProfiles();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [canceledProfiles, setCanceledProfiles] = useState<UserProfile[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLike = async () => {
    const currentProfile = profiles[currentIndex];
    if (currentProfile) {
      try {
        await likeForm(currentProfile.id);
        await fetchNext();
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsExpanded(false);
      } catch (error) {
        console.error("Ошибка при установке лайка:", error);
        alert("Не удалось установить лайк. Попробуйте еще раз.");
      }
    }
  };

  const handleCancel = async () => {
    const currentProfile = profiles[currentIndex];
    if (currentProfile) {
      try {
        await dislikeForm(currentProfile.id);
        setCanceledProfiles((prev) => [...prev, currentProfile]);
        await fetchNext();
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsExpanded(false);
      } catch (error) {
        if (error.message) {
          try {
            const details = JSON.parse(error.message);
            const messages = details
              .map((detail: any) => detail.msg)
              .join(", ");
            alert(`Ошибка валидации: ${messages}`);
          } catch (parseError) {
            alert("Ошибка при установке дизлайка.");
          }
        } else {
          console.error("Ошибка при установке дизлайка:", error);
          alert("Не удалось установить дизлайк. Попробуйте еще раз.");
        }
      }
    }
  };

  const handlePrevious = () => {
    if (canceledProfiles.length > 0) {
      const lastCanceledProfile = canceledProfiles[canceledProfiles.length - 1];
      setCanceledProfiles((prev) => prev.slice(0, -1));
      const previousIndex = profiles.findIndex(
        (profile) => profile.id === lastCanceledProfile.id
      );
      if (previousIndex !== -1) {
        setCurrentIndex(previousIndex);
        setIsExpanded(false);
      }
    }
  };

  return (
    <>
      <GroupButtonLocal />
      <div className={styles.container}>
        {loading && <div>Загрузка...</div>}

        {error && <div>Ошибка: {error}</div>}

        {profiles[currentIndex] && (
          <div key={profiles[currentIndex].id} className={styles.profile}>
            <div
              className={`${styles.image} ${isExpanded ? styles.expanded : ""}`}
            >
              <img
                src={profiles[currentIndex].photo_links[0]}
                alt={profiles[currentIndex].name}
              />
            </div>
            <div
              className={`${styles.info} ${isExpanded ? styles.expanded : ""}`}
            >
              <div className={styles.status}>
                <Status status="Был сегодня" place={"Unknown"} />
              </div>
              <div className={styles.name}>
                <p className={`${isExpanded ? styles.expanded : ""}`}>
                  {profiles[currentIndex].name}, {profiles[currentIndex].age}
                </p>
                <VerifiedIcon />
              </div>
              <div
                className={`${styles.description} ${
                  isExpanded ? styles.expanded : ""
                }`}
              >
                <p onClick={toggleText}>
                  {isExpanded
                    ? profiles[currentIndex].description
                    : profiles[currentIndex].description.slice(0, 100) + "..."}
                </p>
              </div>

              <div
                className={`${styles.buttons} ${
                  isExpanded ? styles.expanded : ""
                }`}
              >
                {!isExpanded && (
                  <Button
                    title={<PreviousIcon />}
                    type="previous"
                    onClick={handlePrevious}
                  />
                )}

                <div
                  className={`${styles.reaction} ${
                    isExpanded ? styles.expanded : ""
                  }`}
                >
                  <Button
                    title={<CancelIcon />}
                    type="cancel"
                    onClick={handleCancel}
                  />
                  <Button
                    title={<LikeIcon />}
                    type="like"
                    onClick={handleLike}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfiles;
