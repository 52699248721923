import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

interface UserState {
  name: string;
  dateOfBirth: string;
  location: string;
  about: string;
}

const initialState: UserState = {
  name: "",
  dateOfBirth: "",
  location: "",
  about: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setDateOfBirth(state, action: PayloadAction<string>) {
      state.dateOfBirth = action.payload;
    },
    setLocation(state, action: PayloadAction<string>) {
      state.location = action.payload;
    },
    setAbout(state, action: PayloadAction<string>) {
      state.about = action.payload;
    },
  },
});

export const { setName, setDateOfBirth, setLocation, setAbout } =
  userSlice.actions;

export const selectName = (state: RootState) => state.user.name;
export const selectDateOfBirth = (state: RootState) => state.user.dateOfBirth;
export const selectLocation = (state: RootState) => state.user.location;
export const selectAbout = (state: RootState) => state.user.about;

export default userSlice.reducer;
