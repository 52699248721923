import React, { FC } from "react";
import styles from "./Title.module.scss";
import classNames from "classnames";

interface TitleProps {
  firstTitle: string;
  lastTitle?: string;
  className?:string;
}

const Title: FC<TitleProps> = ({ firstTitle, lastTitle, className }) => {
  return (
    <h1 className={classNames(styles.titleWrap, className)}>
      <p className={styles.firstTitle}>{firstTitle}</p>
      <p className={styles.lastTitle}>{lastTitle}</p>
    </h1>
  );
};
export default Title;
