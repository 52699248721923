import React, { useState, useEffect } from "react";
import style from "./InputName.module.scss";
import classNames from "classnames";
import { CheckIcon } from "icons/CheckIcon";

interface InputProps {
  placeholder?: string;
  type?: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
}

export const InputName: React.FC<InputProps> = (props) => {
  const [isFilled, setIsFilled] = useState(false);
  const [inputValue, setInputValue] = useState(props.text || ""); 

  useEffect(() => {
    setInputValue(props.text || "");
  }, [props.text]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    props.onChange(e); 
    setIsFilled(newValue.trim().length > 0); 
  };

  return (
    <div className={classNames(style.container, props.className)}>
      <input
        className={style.input}
        type={props.type}
        placeholder={props.placeholder}
        value={inputValue} 
        onChange={handleInputChange}
      />
      <div className={style.contentContainer}>{isFilled && <CheckIcon />}</div>
    </div>
  );
};

export default InputName;
