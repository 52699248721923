import React from "react";
import styles from "./AddPhotoInCircle.module.scss";

interface AddPhotoInCircleProps {
  photo: string | null;
}

const AddPhotoInCircle: React.FC<AddPhotoInCircleProps> = ({ photo }) => {
  return (
    <div className={styles.circlePreview}>
      {photo ? (
        <img src={photo} alt="Circle Preview" className={styles.circleImage} />
      ) : (
        <div className={styles.previewImage}></div>
      )}
    </div>
  );
};

export default AddPhotoInCircle;
