import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  selectName,
  selectDateOfBirth,
  selectLocation,
  selectAbout,
} from "../../store/userSlice";
import Title from "components/Title/Title";
import Button from "components/Button/Button";
import AddPhoto from "components/AddPhoto/AddPhoto";
import styles from "./UserPhotoAgreement.module.scss";
import Stepper from "components/Stepper/Stepper";
import useRegister from "hooks/useRegister";

const UserPhotoAgreement: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>([]);

  const navigate = useNavigate();
  const register = useRegister();

  const name = useSelector(selectName);
  const dateOfBirth = useSelector(selectDateOfBirth);
  const location = useSelector(selectLocation);
  const about = useSelector(selectAbout);

  const handleNextClick = async () => {
    if (isChecked && photoUploaded) {
      const userData = {
        name,
        dateOfBirth,
        location,
        about,
        photos: selectedFiles.filter((file) => file !== null),
      };

      console.log("User Data:", userData);

      await register();

      navigate("/main-session");
    } else {
      alert("Пожалуйста, согласитесь с условиями и загрузите фото.");
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handlePhotoChange = (files: (File | null)[]) => {
    setSelectedFiles(files);
    setPhotoUploaded(files.some((file) => file !== null));
  };

  return (
    <div className={styles.registerStepThree}>
      <Stepper currentStep={3} />
      <div className={styles.title}>
        <Title firstTitle="Добавьте фото" />
      </div>

      <div className={styles.photoContainer}>
        <AddPhoto onPhotoChange={handlePhotoChange} length={4} />
      </div>

      <div className={styles.checkboxContainer}>
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className={styles.customRadio}
          />
        </label>
        <div className={styles.infos}>
          <p>
            Я согласен с{" "}
            <span className={styles.privacyText}>
              политикой конфиденциальности
            </span>
          </p>
          <p>
            и с{" "}
            <span className={styles.agreementText}>
              пользовательским соглашением
            </span>
          </p>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          title="Создать профиль"
          onClick={handleNextClick}
          disabled={!isChecked || !photoUploaded}
        />
      </div>
    </div>
  );
};

export default UserPhotoAgreement;
